import { lazy } from "react";

const login =lazy(()=>import('../pages/auth/logIn/login'));
const Dashboard =lazy(()=>import('../pages/dashboard'));
const forgotPassword =lazy(()=>import('../pages/auth/forgotPassword/forgotPassword'));
const UserDetail =lazy(()=>import('../pages/userDetail'));
const OrganizationDashboard =lazy(()=>import('../pages/organizations'));
const UserWebReport =lazy(()=>import('../pages/userWebReport'));
const UserEachLogs =lazy(()=>import('../pages/userWebReport/componets/EachLogsData'));
export const ALL_LINKS={
    LOGIN:{
        pageLink:'/',
        loginRequired:false,
        view:login
    },
    FORGOT_PASSWORD:{
        pageLink:'/forgot-password',
        loginRequired:false,
        view:forgotPassword
    },
    DASHBOARD:{
        pageLink:'/dashboard',
        loginRequired:true,
        view:Dashboard
    },
    USER_DETAIL:{
        pageLink:'/user-detail',
        loginRequired:true,
        view:UserDetail
    },
    ORGANIZATION:{
        pageLink:'/organization',
        loginRequired:true,
        view:OrganizationDashboard
    },
    USERS:{
        pageLink:'/organization/2/users',
        loginRequired:true,
        view:Dashboard
    },
    WEBREPORT:{
        pageLink:'/user/report',
        loginRequired:false,
        view:UserWebReport
    },
    WEBEACHLOGS:{
        pageLink:'/logs/Eachitem',
        loginRequired:false,
        view:UserEachLogs
    }
    
}