import { useState } from "react";
import React from "react";
import { toast } from "react-toastify";
import { logout } from "../services/authServices";
import jwt from 'jwt-decode';

const AuthContext = React.createContext({
  role: "",
  token: "",
  org_id: "",
  email_id: "",
  isLoggedIn: false,
  isLastUser: false,
  currentPage: "",
  dataMaxCount:"",
  orgDataMaxCount:"",
  login: (token) => { },
  logout: () => { },
  updateRole: () => { },
  userProfile: () => { },
  tokenValidater: () => { },
  updateLastUser:() => { },
  updateDataMaxCount:() => { },
  updateOrgDataMaxCount:() => { },
  updateUserPageChange:() => { },
})

const retrieveStoredToken = () => {
  const token = localStorage.getItem("token");
  const org_id = localStorage.getItem("org_id");
  const role = localStorage.getItem("role");
  const time = localStorage.getItem("expire_In");
  const email_id = localStorage.getItem("email_id");
  const currentPage = parseInt(localStorage.getItem("page"))
  return { token, org_id, email_id, role, time, currentPage };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  let initialToken;
  let initialOrgid;
  let initialRole;
  let initialPage;
  let initialEmailId;
  if (tokenData) {
    initialToken = tokenData.token;
    initialOrgid = tokenData.org_id;
    initialRole = tokenData.role;
    initialPage = tokenData.currentPage;
    initialEmailId = tokenData.email_id;
  }
  const [token, setToken] = useState(initialToken);
  const [role, setRole] = useState(initialRole || 'user');
  const [org_id, setOrgid] = useState(initialOrgid);
  const [email_id, setEmailId] = useState(initialEmailId);
  const [isLoggedIn, setIsLoggedIn] = useState(!!initialToken)
  const [isLastUser, setIsLastUser] = useState(false)
  const [dataMaxCount, setDataMaxCount] = useState("")
  const [orgDataMaxCount, setOrgDataMaxCount] = useState("")
  const [currentPage, setCurrentPage] = React.useState(initialPage ? initialPage : 1);

  const updateLastUser=async()=>{
    const page = await localStorage.getItem('page')
    if((page > 1) && ((dataMaxCount % 10) === 1)){
     await localStorage.setItem('page', page - 1)
    }
    setIsLastUser(true)
  }

  const updateDataMaxCount = async(count) => {
    setDataMaxCount(count)
  }

  const updateOrgDataMaxCount = async(count) => {
    setOrgDataMaxCount(count)
  }
  const updateRoleHandler = (role) => {
    localStorage.setItem('role', role);
    setRole(role);
  }

  const logoutHandler = async () => {
    try {
      const res = await logout({ token: token });
      setToken(null);
      setIsLoggedIn(false);
      setOrgid(null);
      setRole(null);
      setEmailId(null);
      localStorage.removeItem("token");
      localStorage.removeItem("org_id");
      localStorage.removeItem("email_id");
      localStorage.removeItem("role");
      localStorage.removeItem("expire_In");
      localStorage.removeItem("page");
      localStorage.removeItem("orgPage");
    }
    catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        setToken(null);
        setIsLoggedIn(false);
        setOrgid(null);
        setRole(null);
        setEmailId(null);
        localStorage.removeItem("token");
        localStorage.removeItem("org_id");
        localStorage.removeItem("email_id");
        localStorage.removeItem("role");
        localStorage.removeItem("expire_In");
        localStorage.removeItem("page");
        localStorage.removeItem("orgPage");
      } else {
        toast.error(err.response.data.message);
      }
    }
  };

  const loginHandler = (token, org_id, email_id, role, time) => {
    setToken(token);
    setOrgid(org_id);
    setIsLoggedIn(true);
    setRole(role);
    setEmailId(email_id);
    localStorage.setItem("token", token);
    localStorage.setItem("org_id", org_id);
    localStorage.setItem("email_id", email_id);
    localStorage.setItem("role", role);
    localStorage.setItem("expire_In", JSON.stringify(Date.now() + (time * 1000)));
  };

  const userProfileHandler = async () => {
    const token = await localStorage.getItem('token');
    try {
      const decodedToken = await jwt(token);
      if (decodedToken && decodedToken.nickname) {
        return (decodedToken.nickname.charAt(0).toUpperCase() + decodedToken.nickname.slice(1));
      }
    } catch (error) {
      console.error('Error decoding JWT:', error);
    }
  }
  const validateToken = async () => {
    const token = await localStorage.getItem('token');
    try {
      const decodedToken = await jwt(token, { complete: true });
      const isTokenExpired = await Date.now() >= decodedToken.exp * 1000;
      if (isTokenExpired === true) {
        return {
          isValid: false,
          message: 'Token has expired',
        };
      } else {
        return {
          isValid: true,
          message: 'Token is still valid',
          decodedToken: decodedToken.payload,
        };
      }
    } catch (error) {
      console.error('Error decoding JWT:', error);
    }
  }

  const updateUserPageChange =(page)=>{
    setCurrentPage(page)
    localStorage.setItem('page',page)
  }

  const tokenValue = {
    token: token,
    org_id: org_id,
    email_id: email_id,
    role: role,
    isLoggedIn: isLoggedIn,
    isLastUser: isLastUser,
    dataMaxCount: dataMaxCount,
    orgDataMaxCount: orgDataMaxCount,
    currentPage: currentPage,
    login: loginHandler,
    logout: logoutHandler,
    updateRole: updateRoleHandler,
    userProfile: userProfileHandler,
    tokenValidater: validateToken,
    updateLastUser: updateLastUser,
    updateDataMaxCount: updateDataMaxCount,
    updateOrgDataMaxCount: updateOrgDataMaxCount,
    updateUserPageChange: updateUserPageChange
  };

  return (
    <AuthContext.Provider value={tokenValue}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext